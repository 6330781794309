import whenDomReady from "when-dom-ready";

function initialise() {
  const passwordInput = document.querySelector(".js-password-input");
  const passwordToggle = document.querySelector(".js-password-toggle");

  if (!passwordInput || !passwordToggle) {
    return;
  }

  passwordToggle.addEventListener("click", () => {
    if (passwordToggle.matches('[data-password-visibility="true"]')) {
      passwordToggle.setAttribute("data-password-visibility", "false");
      passwordInput.type = "password";
    } else {
      passwordToggle.setAttribute("data-password-visibility", "true");
      passwordInput.type = "text";
    }
  });
}

whenDomReady().then(initialise);

/**
 * This file handles displaying a loading indicator and
 * disabling a button when clicked.
 *
 * Used by adding the "data-disable-click" attribute.
 *
 * E.g.
 *
 * <button class="btn btn-primary"
 *         name="button"
 *         value="login"
 *         data-disable-click="screen">
 *   Login
 * </button>
 *
 * If option is "screen" then also show a loading overlay,
 * otherwise only disable the button.
 */

import whenDomReady from "when-dom-ready";

function initialise() {
  const buttons = Array.from(document.querySelectorAll("[data-disable-click]"));

  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      // Display overlay immediately.
      if (button.dataset.disableClick === "screen") {
        document.body.classList.add("is-loading");
      }

      // Set disabled on next frame to prevent halting form submission.
      requestAnimationFrame(() => {
        button.disabled = true;
      });
    });
  });
}

whenDomReady().then(initialise);
